/* You can add global styles to this file, and also import other style files */
@import url("./assets/css/bootstrap/bootstrap.css");
/* Using a url */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

@font-face {
    font-family: 'Kanit';
    src: url('./assets/fonts/kanit/Kanit-Regular.eot');
    src: url('./assets/fonts/kanit/Kanit-Regular.woff') format('woff'),
        url('./assets/fonts/kanit/Kanit-Regular.otf') format('opentype'),
        url('./assets/fonts/kanit/Kanit-Regular.ttf') format('truetype'),
        url('./assets/fonts/kanit/Kanit-Regular.svg#filename') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Kanit', sans-serif;
    background-color: rgba(0, 0, 0, 0.5);
    /* Change the last value (0.5) to adjust the transparency */
    background-image: url('./assets/img/bg.png');
    /* Other styling properties for the body */
}


.dropdown-toggle::after {
    display: none;
}

.dropdown-toggle {
    background-color: transparent !important;
    border: none !important;
}

.dropdown-menu {
    padding: 0.5rem;
}

.dropdown-menu a:hover {
    color: #fff;
    background-color: #0d6efd;
}

.dropdown-menu a:active {
    color: #fff;
    background-color: #0a58ca;
}

.text-align-right {
    text-align: right !important;
}

.text-align-right {
    text-align: right !important;
}